import {useState, useEffect } from 'react';

import FireShoppingBag from '../Components/FireShoppingBag';
import FireLogo from '../Components/FireLogo';
import FireDrawer from '../Components/FireDrawer';
import FirePopover from '../Components/FirePopover';

import { helpPages } from '../Components/HelpNavbar';
import { cn } from "@/lib/utils";

import {
    ShoppingBag,
    Heart,
    Search as SearchIcon,
    Menu as MenuIcon,
    Box as BoxIcon,
    Gift,
    CircleHelp,
    ChevronLeft,
    ChevronRight,
} from "lucide-react";

type subNavItemTypes = {
    subItemName: string,
    href: string;
}

type mainMenuTypes = {
    title: string;
    subItems: subNavItemTypes[]
}

type menuProps = {
    name: string;
    href: string;
    subNav: mainMenuTypes[]
}

function Navbar() {
    const [mobileNavPage, setMobileNavPage] = useState<number>(1);
    const [mainNavItems, setMainNavItems] = useState<mainMenuTypes[]>([]);
    const [subNavItems, setSubNavItems] = useState<subNavItemTypes[]>([]);
    const [subItemTitles, setSubItemTitles] = useState<string[]>([]);

    const menuData: menuProps[] = [
        {
            name: 'duvar sanatı',
            href: '#',
            subNav: [
                {
                    title: 'Featured',
                    subItems: [
                        {
                            subItemName: 'New Arrivals',
                            href: '#'
                        },
                        {
                            subItemName: 'Best Sellers',
                            href: '#'
                        },
                        {
                            subItemName: 'from a Set of 2',
                            href: '#'
                        },
                        {
                            subItemName: 'from a Set of 3',
                            href: '#'
                        },
                        {
                            subItemName: 'from a Set of 4',
                            href: '#'
                        },
                    ]
                },
                {
                    title: 'Collections',
                    subItems: [
                        {
                            subItemName: 'Altered Prints',
                            href: '#'
                        },
                        {
                            subItemName: 'Collage Prints',
                            href: '#'
                        },
                        {
                            subItemName: 'Exhibition Prints',
                            href: '#'
                        },
                        {
                            subItemName: 'Feminist Prints',
                            href: '#'
                        },
                        {
                            subItemName: 'Japanese Prints',
                            href: '#'
                        },
                        {
                            subItemName: 'Lyrics Prints',
                            href: '#'
                        },
                        {
                            subItemName: 'Masterpieces',
                            href: '#'
                        },
                    ]
                },
                {
                    title: 'Showcased',
                    subItems: [
                        {
                            subItemName: 'Everyday Quotes Prints',
                            href: '#'
                        },
                        {
                            subItemName: 'Iconic Collage Portraits Prints',
                            href: '#'
                        },
                        {
                            subItemName: 'Portraits of Beauties Prints',
                            href: '#'
                        },
                        {
                            subItemName: 'Iconic Exhibition Celebrities',
                            href: '#'
                        },
                    ]
                },
                {
                    title: 'Colors',
                    subItems: [
                        {
                            subItemName: 'Black & White Prints11',
                            href: '#'
                        },
                    ]
                }
            ]
        },
        {
            name: 'hayat',
            href: '#',
            subNav: [
                {
                    title: 'Coffee Mugs',
                    subItems: [
                        {
                            subItemName: 'New Arrivals',
                            href: '#'
                        },
                        {
                            subItemName: 'Best Sellers',
                            href: '#'
                        },
                        {
                            subItemName: 'from a Set of 2',
                            href: '#'
                        },
                        {
                            subItemName: 'from a Set of 3',
                            href: '#'
                        },
                        {
                            subItemName: 'from a Set of 4',
                            href: '#'
                        },
                    ]
                },
                {
                    title: 'Categories',
                    subItems: [
                        {
                            subItemName: 'Abstract',
                            href: '#'
                        },
                        {
                            subItemName: 'Black and White',
                            href: '#'
                        },
                        {
                            subItemName: 'Geometric',
                            href: '#'
                        },
                        {
                            subItemName: 'Pop Art',
                            href: '#'
                        },
                        {
                            subItemName: 'Typography',
                            href: '#'
                        },
                        {
                            subItemName: 'Urban Art',
                            href: '#'
                        },
                    ]
                },
                {
                    title: 'Collections',
                    subItems: [
                        {
                            subItemName: 'A Week in New York',
                            href: '#'
                        },
                        {
                            subItemName: 'Everyday Quotes',
                            href: '#'
                        },
                        {
                            subItemName: 'Stay Golden',
                            href: '#'
                        },
                        {
                            subItemName: 'Holidays',
                            href: '#'
                        },
                        {
                            subItemName: 'Christmas',
                            href: '#'
                        },
                        {
                            subItemName: 'Fathers Day',
                            href: '#'
                        },
                    ]
                },
                {
                    title: 'Holidays',
                    subItems: [
                        {
                            subItemName: 'Christmas',
                            href: '#'
                        },
                        {
                            subItemName: 'Fathers Day',
                            href: '#'
                        },
                        {
                            subItemName: 'Valentines Day',
                            href: '#'
                        },
                    ]
                },
                {
                    title: 'Themes',
                    subItems: [
                        {
                            subItemName: 'Japanese',
                            href: '#'
                        },
                        {
                            subItemName: 'Quote',
                            href: '#'
                        },
                        {
                            subItemName: 'Retro',
                            href: '#'
                        },
                        {
                            subItemName: 'Skull',
                            href: '#'
                        },
                    ]
                }
            ]
        },
        {
            name: 'teknoloji',
            href: '#',
            subNav: []
        },
        {
            name: 'giyim',
            href: '#',
            subNav: []
        },
        {
            name: 'koleksiyonlar',
            href: '#',
            subNav: []
        },
    ];

    const getMainNav = (subNav: mainMenuTypes[], mainMenuTitle: string) => {
        setMainNavItems(subNav);
        setSubItemTitles([mainMenuTitle])
    }

    const getSubNavItem = (subItems:subNavItemTypes[], subItemTitle: string) => {
        setSubItemTitles((prev) => [...prev, subItemTitle])
        setSubNavItems(subItems);
    }

    const [currentDiv, setCurrentDiv] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentDiv(prev => (prev === 0 ? 1 : 0));
      }, 6000);
  
      return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div className='hidden lg:flex border-b bg-fire-blue border-[#303f50] flex-1 lg:px-14 items-center justify-end sm:items-stretch'>
                <div className='flex space-x-3 text-center text-white items-baseline'>
                    <a 
                        href="#" 
                        className="capitalize py-2 text-xs font-semibold text-white" 
                        aria-current="page"
                    >Hediye kartı</a>
                    <span className="link-spacer">|</span>
                    <FirePopover
                        trigger='hover'
                        contentClassName='w-full lg:w-[200px] mt-[8px] -right-2 bg-white text-black'
                        content={
                            <div className='px-[20px] py-[20px]'>
                                <p className='text-start text-[16px] font-bold leading-[20px] mb-[20px]'>Yardım</p>
                                <ul className='text-start space-y-1'>
                                    {helpPages.length > 0 && helpPages.map((helpItem, index) => {
                                        return (
                                            <li key={index}>
                                                <a 
                                                    className={`
                                                        text-[14px] font-[500] leading-[21px]
                                                        ${helpItem.active ? 'text-black hover:text-fire-gray' : 'text-fire-gray'}
                                                        hover:text-black
                                                    `}
                                                    href={helpItem.href}
                                                >{helpItem.label}</a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        }
                    >
                        <a 
                            href="#" 
                            className="capitalize py-2 text-xs font-semibold text-white" 
                            aria-current="page"
                        >Yardım</a>
                    </FirePopover>
                    <span className="link-spacer">|</span>
                    <a 
                        href={route('register')}
                        className="capitalize py-2 text-xs font-semibold text-white" 
                        aria-current="page"
                    >Kayıt ol</a>
                    <span className="link-spacer">|</span>
                    <a 
                        href={route('login')}
                        className="capitalize py-2 text-xs font-semibold text-white" 
                        aria-current="page"
                    >Giriş yap</a>
                </div>
            </div>

            <nav className='mx-auto bg-fire-blue z-10 sticky top-0'>
                <div className='grid grid-cols-3 xs:grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xs:px-0 sm:px-6 lg:px-8 mx-2 lg:mx-4 h-[60px] lg:h-auto items-center justify-between'>
                    {/** Mobil menü */}
                    <div className='inset-y-0 left-0 flex items-center lg:hidden'>
                        <FireDrawer
                            buttonContent={
                                <MenuIcon size={32} className='cursor-pointer text-white' />
                            }
                            backgroundColor='white'
                            side='left'
                            padding='px-[15px] py-[5px]'
                            hiddenCloseIcon={mobileNavPage > 1}
                        >
                                <div className={cn(
                                        'mt-[50px] px-[8px]',
                                        mobileNavPage === 1 ? 'animate-slideOverIn block' : ' animate-slideOverOut hidden opacity-0'
                                    )}>
                                    <div className='grid space-y-2'>
                                        {menuData.length > 0 && menuData.map((item, index) => {
                                            const isSubItems = item.subNav.length > 0;

                                            return (
                                                <div key={index} className='flex items-center cursor-pointer'>
                                                    <a 
                                                        href={isSubItems ? undefined : item.href} 
                                                        className="w-full capitalize text-[#000000] text-[22px] font-[600]" 
                                                        aria-current="page"
                                                        onClick={() => {
                                                            if(isSubItems) {
                                                                setMobileNavPage(2);
                                                                getMainNav(item.subNav, item.name)
                                                            }
                                                        }}
                                                    >{item.name}</a>
                                                    {isSubItems &&  <ChevronRight fontSize={28} /> }
                                                </div>
                                        )})}
                                    </div>
                                    <div className='grid my-9'>
                                        <div className='pb-7'>
                                            <p className='text-[17px] font-[500] text-gray-600'>Kayıt ol ve ilham alın</p>
                                        </div>
                                        <div className='flex flex-1 gap-2 flex-wrap'>
                                            <a 
                                                className='bg-black w-fit text-[#ffffff] text-[15px] font-[700] mr-2 rounded-full border border-black px-[35px] py-[12px]'
                                                href={route('register')}
                                            >Kayıt ol</a>
                                            <a 
                                                className='bg-white w-fit text-[#000000] text-[15px] font-[700] rounded-full border border-gray px-[35px] py-[12px]' 
                                                href={route('login')}
                                            >Giriş yap</a>
                                        </div>
                                    </div>
                                    <div className='grid space-y-3 mt-[60px]'>
                                        <div className='flex space-x-4 items-center'>
                                            <ShoppingBag />
                                            <a 
                                                href="#" 
                                                className="capitalize py-1 text-md font-semibold text-black" 
                                                aria-current="page"
                                            >Sepet</a>
                                        </div>
                                        <div className='flex space-x-4 items-center'>
                                            <BoxIcon />
                                            <a 
                                                href="#" 
                                                className="capitalize py-1 text-md font-semibold text-black" 
                                                aria-current="page"
                                            >Siparişler</a>
                                        </div>
                                        <div className='flex space-x-4 items-center'>
                                            <Gift />
                                            <a 
                                                href="#" 
                                                className="capitalize py-1 text-md font-semibold text-black" 
                                                aria-current="page"
                                            >Hediye kartı</a>
                                        </div>
                                        <div className='flex space-x-4 items-center'>
                                            <CircleHelp />
                                            <a 
                                                href="#" 
                                                className="capitalize py-1 text-md font-semibold text-black" 
                                                aria-current="page"
                                            >Yardım</a>
                                        </div>
                                    </div>
                                </div>

                                <div className={cn(mobileNavPage === 2 ? 'animate-slideOverIn block' : 'animate-slideOverOut hidden opacity-0')}>
                                    <div
                                        onClick={() => setMobileNavPage(mobileNavPage -1)} 
                                        className='relative -left-3 pt-[5px] cursor-pointer flex font-[700] items-center'
                                    >
                                        <ChevronLeft size={31} className='w-fit p-0' /> Tümü
                                    </div>
                                    <div className='mt-[25px] px-[8px] '>
                                        <div className='grid space-y-3'>
                                            {mainNavItems.length > 0 && mainNavItems.map((subItem, subIndex) => (
                                                <div key={subIndex} className='flex items-center cursor-pointer'>
                                                    <a 
                                                        key={subIndex}
                                                        className="w-full capitalize text-[#000000] text-[22px] font-[600]" 
                                                        aria-current="page"
                                                        onClick={() =>  {
                                                            setMobileNavPage(3);
                                                            getSubNavItem(subItem.subItems, subItem.title);
                                                        }}
                                                    >{subItem.title}</a>
                                                    <ChevronRight fontSize={28} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div className={cn(mobileNavPage === 3 ? 'animate-slideOverIn block' : 'animate-slideOverOut hidden opacity-0 ')}>
                                    <div
                                        onClick={() => setMobileNavPage(mobileNavPage -1)} 
                                        className='relative -left-3 pt-[5px] cursor-pointer flex font-[700] items-center capitalize'
                                    >
                                        <ChevronLeft size={31} className='right-4' /> {subItemTitles[0]}
                                    </div>
                                    <div className='mt-[25px] px-[8px] space-y-4'>
                                        <a className='capitalize text-[#000000] text-[22px] font-[600]'>{subItemTitles[1]}</a>
                                        <div className='grid space-y-2'>
                                            {subNavItems.length > 0 &&  subNavItems.map((subItem, index) => (
                                                <a 
                                                    key={index}
                                                    href={subItem.href} 
                                                    className="capitalize text-[#000000] text-[16px] font-[400]" 
                                                    aria-current="page"
                                                >{subItem.subItemName}</a>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                        </FireDrawer>
                    </div>
                    <div className='flex justify-center lg:justify-start flex-shrink-0 items-center py-2'>
                        <FireLogo size='normal' />
                    </div>
                    <FirePopover
                        trigger='hover'
                        popupClassName='!w-full hidden lg:flex'
                        contentClassName={`mt-[11px] !w-[99vw] flex justify-center space-x-8 py-4 overflow-hidden bg-[#ffffff] text-sm shadow-xl`}
                        hasContent={mainNavItems.length > 0}
                        content={
                            <>
                                {mainNavItems.map((item, index) => (
                                    <div className="p-4" key={index}>
                                        <h3 className="font-semibold mb-4 text-[18px]">{item.title}</h3>
                                        <ul>
                                            {item.subItems.map((SubItem, index) => (
                                                <li key={index} className="mb-1">
                                                    <a href={SubItem.href}>{SubItem.subItemName}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </>
                        }
                    >
                        <div  className='hidden flex-1  w-full hidden lg:flex float-end items-center justify-center sm:items-stretch'>
                            <div className='flex space-x-5'>
                                {menuData.length > 0 && menuData.map((item, index) => (
                                    <a 
                                        key={index}
                                        onMouseOver={() => getMainNav(item.subNav, item.name)}
                                        href={item.href} 
                                        className="capitalize w-max py-2 text-base font-semibold text-white border-b-2 border-transparent hover:border-b-white" 
                                        aria-current="page"
                                    >{item.name}</a>
                                ))}
                            </div>
                        </div>
                    </FirePopover>
                    
                    <div className='flex justify-end items-center sm:ml-6 pl-5'>
                        <FireDrawer
                            buttonContent={
                                <SearchIcon className='cursor-pointer text-white' size={24} />
                            }
                            backgroundColor='fire-blue'
                            side='top'
                            padding='px-[15px] py-[15px]'
                            margin='mt-[25px]'
                            colorCloseIcon='white'
                        >
                            <div className='grid grid-cols-1 lg:grid-cols-3'>
                                <div className='hidden lg:block'>
                                    <FireLogo  size='normal' />
                                </div>
                                <div
                                    className='grid mx-4'
                                >
                                    <div className="relative mt-2 rounded-md shadow-sm">
                                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                            <SearchIcon className='cursor-pointer' size={16} />
                                        </div>
                                        <input 
                                            type="text" 
                                            name="search" 
                                            id="search" 
                                            className="block w-full rounded-full py-1.5 pl-10 text-gray-900 placeholder:text-gray-400 bg-gray-100 focus:bg-gray-200 border-none focus:border-transparent focus:ring-0" 
                                            placeholder="Arama" 
                                        />
                                    </div>
                                    <div className='mt-4'>
                                        <p className='text-md font-semibold text-gray-400'>Popüler arama terimleri</p>
                                        <ul className='space-y-3 mt-4'>
                                            <li className="mb-1">
                                                <a href="#" className='font-semibold text-white'>Mug</a>
                                            </li>
                                            <li className="mb-1">
                                                <a href="#" className='font-semibold text-white'>Pop art</a>
                                            </li>
                                            <li className="mb-1">
                                                <a href="#" className='font-semibold text-white'>İphone</a>
                                            </li>
                                            <li className="mb-1">
                                                <a href="#" className='font-semibold text-white'>Prints</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </FireDrawer>
                        <Heart className='mx-4 lg:mx-5 cursor-pointer text-white' size={24} />
                        <FireShoppingBag />
                    </div>
                </div>
            </nav>
            <div className='relative block overflow-hidden text-center h-11'>
                <div className={cn('absolute bg-[#dbe8f2] content-center w-full h-11', currentDiv === 0 ? 'animate-slideUp visible' : 'animate-slideDown invisible opacity-0')} >
                    <p className='[font-size:_clamp(0.7rem,1vw,0.9rem)] font-[600]'>Tüm Kredi Kartlarına Peşin Fiyatına 3 Ay Taksit İmkanı</p>
                </div> 
                <div className={cn('absolute bg-[#ebff8a] content-center w-full h-11', currentDiv === 1 ? 'animate-slideUp visible' : 'animate-slideDown invisible opacity-0')}>
                    <p className='[font-size:_clamp(0.7rem,1vw,0.9rem)] font-[600]'>Tüm Kredi Kartlarına Peşin Fiyatına 3 Ay Taksit İmkanı</p>
                </div> 
            </div>
        </>
    )
}

export default Navbar